import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { USE_CASES } from '~/components/use-cases/Opener';

const UseCasesIndex: React.FC = () => {
  useEffect(() => {
    navigate(`/use-cases/${USE_CASES[0].slug}/`);
  }, []);
  return null;
};

export default UseCasesIndex;
